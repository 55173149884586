:root {
  --swiper-navigation-color: rgba(0, 0, 0, 0.87);
  --swiper-navigation-size: 24px;
}

.swiper-controls {
  position: relative;
  margin: 20px 10px 0;
  padding: 0 0 20px;
  overflow-y: visible;
  box-sizing: border-box;
}

.swiper-slide {
  display: flex;
  align-self: flex-end;
  justify-content: center;
}

.swiper-button-prev,
.swiper-button-next {
  top: 0;
  height: 100%;
  width: 40px;
  background: #fff;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0;
}

.carousels {
  margin: 0 40px;
}
