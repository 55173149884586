.pdf-preview-container {
  display: flex;
  justify-content: center;
}

.pdf-preview-iframe {
  display: block;
  border: none;
  margin: 5px;
  width: 100%;
  min-width: 360px;
  min-height: 500px;

  &.rendered {
    min-width: auto;
    min-height: auto;
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

.page-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 0.5s ease-out 0s;
  z-index: 1;
  width: 100%;
}

.page-selector-button {
  padding: 11px 16px 12px;
  background-color: rgb(255, 255, 255);
  line-height: 17px;
  opacity: 0.7;
  border: 1px solid rgb(170, 170, 170);
  color: var(--app-primary-500);
  cursor: pointer;
}

.page-selector-text {
  padding: 10px 12px;
  color: rgb(255, 255, 255);
  font-size: 13px;
  line-height: 22px;
  background-color: rgb(170, 170, 170);
  opacity: 0.7;
}
